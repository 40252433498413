.notShrinkAnimated {
    transition: transform 1s ease; 
    transform: scale(0.5); 
    
}

.shrinkAnimated {
    transform: scale(1);
}



.notUpAnimated {
    opacity: 0;
    filter: blur(5px);
    transform: translateY(50%);
    transition: all 1s;
    
}
.upAnimated {
    opacity: 1;
    filter: blur(0);
    transform: translateY(0);
    
    
}

.notLeftAnimated {
    opacity: 0;
    filter: blur(5px);
    transform: translateX(-100%);
    transition: all 1s;
   
}
.leftAnimated {
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
    
    
}
.notRightAnimated {
    opacity: 0;
    filter: blur(5px);
    transform: translateX(10%);
    transition: all 1s;
    
}
.rightAnimated {
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
    
    
}

.logo:nth-child(1) {
    transition-delay: 200ms;
  
}
.logo:nth-child(2) {
    transition-delay: 300ms;
   
}
.logo:nth-child(3) {
    transition-delay: 400ms;
 
}
.logo:nth-child(4) {
    transition-delay: 500ms;
  
}
.logo:nth-child(5) {
    transition-delay: 600ms;
   
}



body {
    overflow-x: hidden; /* Prevents horizontal scroll */
}